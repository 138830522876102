:root {
	/* colors */
	--green: rgb(36, 113, 107);
	--green-light: rgb(95, 151, 147);
	--yellow: rgb(244, 172, 69);
	--yellow-light: rgb(252, 230, 199);
	--blue: rgb(27, 38, 79);
	--blue-light: rgb(109, 116, 143);
	--red: rgb(176, 65, 62);
	--red-light: rgb(248, 201, 200);
	--red-dark: rgb(145, 54, 51);
	--grey: rgb(159, 150, 141);
	--grey-dark: rgb(102, 96, 90);
	--grey-light: rgb(212, 207, 201);
	--white: rgb(255, 255, 255);
	--black: rgb(0, 0, 0);
	--black-alt: rgb(49, 49, 49);

	/* default sizing */
	--lg-container: 1000px;
	--sm-container: 400px;
	--nav-height: 60px;

	/* color scheme */
	--primary: var(--yellow);
	--secondary: var(--blue);
	--tertiary: var(--grey);

	/* fonts */
	--primary-font: Akronim;
}

*,
*:before,
*:after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	color: var(--black-alt);
	font-size: 16px;
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

a {
	color: inherit;
	text-decoration: none;
}

h1 {
	font-size: 30px;
	margin: 10px 0;
}

button {
	border-radius: 4px;
	border-style: none;
	padding: 10px;
	margin: 3px;
	font-weight: bold;
	background-color: var(--secondary);
	color: var(--white);
	cursor: pointer;
}

button.red {
	background-color: var(--red);
}

button.green {
	background-color: var(--green);
}

button.grey {
	background-color: var(--grey-dark);
}

input[type="text"] {
	margin: 5px;
	min-width: 280px;
	padding: 5px;
	border: none;
	border-bottom: 1px solid var(--black-alt);
	font-size: 14px;
}

input[type="text"]:focus {
	background-color: var(--yellow-light);
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

input[type="text"].center {
	text-align: center;
}

input[type="text"]:required {
	padding-right: 55px;
	padding-left: 10px;
}

select {
	min-width: 70px;
	padding: 5px;
	margin: 5px;
	font-size: 14px;
}
